import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import 'typeface-metropolis';
import 'typeface-lato';

import authReducer from './store/reducers/auth';
import transactionReducer from './store/reducers/transaction';
import errorReducer from './store/reducers/error';
import merchantReducer from './store/reducers/merchant';
import nmiReducer from './store/reducers/nmi';
import stripeMexicoReducer from './store/reducers/stripeMexico';
import paymentMethodsReducer from './store/reducers/paymentMethods';
import managePaymentMethodsReducer from './store/reducers/managePaymentMethods';
import redirectReducer from './store/reducers/redirect';
import heartlandReducer from './store/reducers/heartland';
import redirectStatusReducer from './store/reducers/redirectStatus';
import stripeReducer from './store/reducers/stripe';
import userTimerReducer from './store/reducers/userTimer';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { injectStore } from './utils/axios-tezpay';

import './i18n.ts';

import logError from './utils/error-logger';

window.onerror = logError;

const rootReducer = combineReducers({
  auth: authReducer,
  transaction: transactionReducer,
  error: errorReducer,
  merchant: merchantReducer,
  nmi: nmiReducer,
  stripeMexico: stripeMexicoReducer,
  paymentMethods: paymentMethodsReducer,
  managePaymentMethods: managePaymentMethodsReducer,
  redirect: redirectReducer,
  heartland: heartlandReducer,
  redirectStatus: redirectStatusReducer,
  stripe: stripeReducer,
  userTimer: userTimerReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

injectStore(store);

const app = (
  <Suspense fallback="Loading...">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
