import {
  AppState,
  GetApplePaySessionObject,
  PayForApplePayTransactionData,
  TransactionStatus,
} from '../types/appState';
import axios from '../../utils/axios-tezpay';
import ApiMethods from '../../constants/urls';
import { ApiTransactionStatus, CreditCardType } from '../../types/api/apiEnums';
import { transactionStatus } from '../../utils/utils';
import {
  setDigitalWalletCreditCard,
  transactionCompleted,
  transactionFail, transactionStart,
} from './transaction';
import { setError } from './error';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export const getApplePaySessionObject = (
  validationUrl: string
): Promise<any> => {
  const url = ApiMethods.Portal.ApplePayTransaction.ApplePaySession;
  const data: GetApplePaySessionObject = {
    ValidationUrl: validationUrl,
  };

  return axios.post(url, data).then((res) => res.data.data);
};

export const payForApplePayTransaction = (
  PaymentToken: string,
  CardType: CreditCardType,
  Last4Digits: string | undefined
) => {
  return (dispatch: ThunkDispatch<AppState, {}, AnyAction>) => {
    dispatch(transactionStart());
    dispatch(setDigitalWalletCreditCard(Last4Digits ?? '', CardType));

    const url = ApiMethods.Portal.ApplePayTransaction.ApplePay;
    const data: PayForApplePayTransactionData = {
      PaymentToken: PaymentToken,
      CardType: CardType,
      Last4Digits: Last4Digits,
    };

    axios
      .post(url, data)
      .then((response) =>
        transactionStatus(
          response.data.data.transactionStatus as ApiTransactionStatus
        )
      )
      .then((result: any) => {
        if (result !== TransactionStatus.Unknown) {
          dispatch(transactionCompleted(result));
        } else {
          if (result === TransactionStatus.Unknown) {
            dispatch(setError('error.unknown-error'));
          } else {
            dispatch(transactionFail());
          }
        }
      })
      .catch((error: any) => {
        dispatch(transactionFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error'));
      });
  };
};
