import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ApiMethods from '../../constants/urls';
import { ApiTransactionStatus, CreditCardType, TenderType } from '../../types/api/apiEnums';
import { transactionStatus } from '../../utils/utils';
import { AppState, PayForHeartlandMobileWalletTransactionData, PaymentMethodType, TransactionStatus } from '../types/appState';
import * as actionTypes from './actionTypes';
import { setError } from './error';
import { setDigitalWalletCreditCard, transactionCompleted, transactionFail } from './transaction';
import axios from '../../utils/axios-tezpay';
import { deletePaymentMethodSuccess, deletePaymentMethodFail } from './paymentMethods';

export const setHeartlandTransactionCreditCard = (creditCardId: string) => {
  return {
    type: actionTypes.SET_HEARTLAND_TRANSACTION_CREDIT_CARD as typeof actionTypes.SET_HEARTLAND_TRANSACTION_CREDIT_CARD,
    creditCardId: creditCardId
  };
};

export const setHeartlandPaymentData = (token: string, address: string, zipcode: string) => {
  return {
    type: actionTypes.SET_HEARTLAND_PAYMENT_DATA as typeof actionTypes.SET_HEARTLAND_PAYMENT_DATA,
    address: address,
    zipcode: zipcode,
    token: token
  };
};

export const payForHeartlandGooglePayTransaction = (
  PaymentToken: string,
  CardType: CreditCardType,
  Last4Digits: string | undefined,
) => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
  ) => {

    dispatch(setDigitalWalletCreditCard(Last4Digits ?? '', CardType))

    let url = ApiMethods.Portal.HeartlandTransaction.GooglePay;
    let data: PayForHeartlandMobileWalletTransactionData = {
      PaymentToken: PaymentToken,
      CardType: CardType,
      Last4Digits: Last4Digits,
    };

    axios
      .post(url, data)
      .then((response) => {
        const status = transactionStatus(response.data.data.transactionStatus as ApiTransactionStatus);
        if (status !== TransactionStatus.Unknown)
          dispatch(transactionCompleted(status))
        else
          dispatch(setError('error.unknown-error'));
      })
      .catch((error) => {
        dispatch(transactionFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error'));
      })
      .finally(() => {

      });
  };
};

export const payForHeartlandTransaction = () => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(payForHeartlandTransactionStart());

    const state = getState();

    const heartland = state.heartland;
    const transaction = state.transaction;

    let url = '';
    let data = null;

    switch (heartland.paymentMethod?.type) {
      case PaymentMethodType.NewCC:
        if (heartland.paymentMethod.token !== '') {
          url = ApiMethods.Portal.HeartlandTransaction.Base;
          data = {
            paymentToken: heartland.paymentMethod.token,
            zipCode: heartland.paymentMethod.zipcode,
            address: heartland.paymentMethod.address,
            last4Digits: transaction.last4Digits,
            cardType: transaction.creditCardType,
            saveCardForFutureUse: state.transaction.savePaymentMethod
          };
        } else {
          console.error('paymentToken is null');
          dispatch(setError('error.unknown-error'));
        }
        break;
      case PaymentMethodType.ExistingCC:
        if (heartland.paymentMethod.paymentMethodId !== null) {
          url = ApiMethods.Portal.HeartlandTransaction.CreditCard;
          url += `/${heartland.paymentMethod.paymentMethodId}`;
        } else {
          console.error('paymentToken is null');
          dispatch(setError('error.unknown-error'));
        }
        break;
      default:
        console.error('unknown payment method');
        dispatch(setError('error.unknown-error'));
        break;
    }

    axios
      .post(url, data)
      .then((response) => {
        const status = transactionStatus(response.data.data.transactionStatus as ApiTransactionStatus);
        if (status !== TransactionStatus.Unknown)
          dispatch(transactionCompleted(status))
        else
          dispatch(setError('error.unknown-error'));
      })
      .catch((error) => {
        dispatch(transactionFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error'));
      })
      .finally(() =>
        dispatch(payForHeartlandTransactionEnd()));

  };
};

export const payForHeartlandTransactionStart = () => {
  return {
    type: actionTypes.PAY_FOR_HEARTLAND_TRANSACTION_START as typeof actionTypes.PAY_FOR_HEARTLAND_TRANSACTION_START,
  };
};

export const payForHeartlandTransactionEnd = () => {
  return {
    type: actionTypes.PAY_FOR_HEARTLAND_TRANSACTION_END as typeof actionTypes.PAY_FOR_HEARTLAND_TRANSACTION_END,
  };
};

export const deleteHeartlandPaymentMethod = (
  paymentMethodId: string,
  tenderType: TenderType
) => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(deleteHeartlandPaymentMethodStart());

    let url;

    switch (tenderType) {
      case TenderType.CreditCard:
        url = ApiMethods.Portal.HeartlandPaymentMethod.CreditCard;
        break;
      default:
        console.error('tenderType is not known');
        dispatch(setError('error.unknown-error'));
        break;
    }

    if (url && url !== '') {
      url += `/${paymentMethodId}`;

      axios
        .delete(url)
        .then(() => {
          dispatch(deletePaymentMethodSuccess());
          dispatch(deleteHeartlandPaymentMethodSuccess());
        })
        .catch((error) => {
          dispatch(deletePaymentMethodFail());
          dispatch(deleteHeartlandPaymentMethodFail());
          if (error.response && error.response.status === 401)
            dispatch(setError('error.unauthorized', false));
          if (error.response && error.response.data.error.errorCode === 2010)
            dispatch(
              setError('error.pending-pre-auth-credit-card-transaction')
            );
          else dispatch(setError('error.api-error'));
        });
    } else {
      console.error('url not set');
      dispatch(setError('error.unknown-error'));
    }
  };
};

export const deleteHeartlandPaymentMethodStart = () => {
  return {
    type: actionTypes.DELETE_HEARTLAND_PAYMENT_METHOD_START as typeof actionTypes.DELETE_HEARTLAND_PAYMENT_METHOD_START,
  };
};

export const deleteHeartlandPaymentMethodSuccess = () => {
  return {
    type: actionTypes.DELETE_HEARTLAND_PAYMENT_METHOD_SUCCESS as typeof actionTypes.DELETE_HEARTLAND_PAYMENT_METHOD_SUCCESS,
  };
};

export const deleteHeartlandPaymentMethodFail = () => {
  return {
    type: actionTypes.DELETE_HEARTLAND_PAYMENT_METHOD_FAIL as typeof actionTypes.DELETE_HEARTLAND_PAYMENT_METHOD_FAIL,
  };
};

export type Actions =
  | ReturnType<typeof setHeartlandPaymentData>
  | ReturnType<typeof setHeartlandTransactionCreditCard>