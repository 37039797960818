import { CreditCardType } from '../../types/api/apiEnums';
import * as actionTypes from '../actions/actionTypes';
import { Actions } from '../actions/stripeMexico';
import { PaymentMethodType, StripeMexicoState } from '../types/appState';

const initialState: StripeMexicoState = {
  paymentMethod: null,
};

const setPaymentToken = (
  state: StripeMexicoState,
  paymentMethodId: string,
  ccType: CreditCardType
): StripeMexicoState => {
  return {
    ...state,
    paymentMethod: {
      ...state.paymentMethod,
      type: PaymentMethodType.NewCC,
      paymentMethodId,
      ccType,
    },
  };
};

const setPaymentData = (state: StripeMexicoState): StripeMexicoState => {
  return {
    ...state,
    paymentMethod: {
      paymentMethodId: '',
      ccType: CreditCardType.Invalid,
      ...state.paymentMethod,
      type: PaymentMethodType.NewCC,
    },
  };
};

const setStripeTransactionCreditCard = (
  state: StripeMexicoState,
  paymentMethodId: string
): StripeMexicoState => {
  return {
    ...state,
    paymentMethod: {
      ...state.paymentMethod,
      type: PaymentMethodType.ExistingCC,
      paymentMethodId,
    },
  };
};

const reducer = (
  state: StripeMexicoState = initialState,
  action: Actions
): StripeMexicoState => {
  switch (action.type) {
    case actionTypes.SET_STRIPE_MEXICO_PAYMENT_TOKEN:
      return setPaymentToken(state, action.paymentToken, action.ccType);
    case actionTypes.SET_STRIPE_MEXICO_PAYMENT_DATA:
      return setPaymentData(state);
    case actionTypes.SET_STRIPE_MEXICO_TRANSACTION_CREDIT_CARD:
      return setStripeTransactionCreditCard(state, action.paymentMethodId);
    default:
      return state;
  }
};

export default reducer;
