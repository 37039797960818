import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AppState,
  MerchantState,
  ProviderType,
  StripeMerchantConfiguration,
} from '../store/types/appState';

const useLoadStripe = () => {
  const [
    stripePromise,
    setStripePromise,
  ] = useState<Promise<Stripe | null> | null>(null);
  const merchant = useSelector<AppState, MerchantState>(
    (state) => state.merchant
  );
  const merchantConfiguration = merchant.configuration as StripeMerchantConfiguration;
  const publishableKey = merchantConfiguration?.publishableKey;

  useEffect(() => {
    if (merchant.providerType === ProviderType.Stripe && !!publishableKey)
      setStripePromise(loadStripe(publishableKey));
  }, [merchant.providerType, publishableKey]);

  return { stripePromise };
};

export default useLoadStripe;
