import React from 'react';
import paymentMethodclasses from '../PaymentMethods/PaymentMethods.module.css';
import { ReactComponent as ApplePayIcon } from '../../../assets/apple-pay.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppState,
  HeartlandMerchantConfiguration,
  MerchantState,
  TransactionState,
} from '../../../store/types/appState';
import { useTranslation } from 'react-i18next';
import {
  getApplePaySessionObject,
  payForApplePayTransaction,
} from '../../../store/actions/applePay';
import { setError } from '../../../store/actions';
import { matchCreditCardType } from '../../../utils/utils';
import { setDigitalWalletCreditCard } from '../../../store/actions/transaction';

const config = (window as any).config;

export type Props = {};

const HeartlandApplePayButton = (props: Props) => {
  const merchant = useSelector<AppState, MerchantState>(
    (state) => state.merchant
  );

  const transaction = useSelector<AppState, TransactionState>(
    (state) => state.transaction
  );

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const heartlandConfiguration = merchant.configuration as HeartlandMerchantConfiguration;

  function onApplePayButtonClicked() {
    if (!ApplePaySession) {
      return;
    }

    // Create ApplePaySession
    const session = new ApplePaySession(config.heartlandApplePay.apiVersion, {
      countryCode: config.heartlandApplePay.countryCode,
      currencyCode: heartlandConfiguration.currencyCode,
      merchantCapabilities:
        config.heartlandApplePay.cardPaymentMethod.parameters
          .merchantCapabilities,
      supportedNetworks:
        config.heartlandApplePay.cardPaymentMethod.parameters
          .allowedCardNetworks,
      total: {
        label: t('payment.apple-pay-label'),
        type: 'final',
        amount: (transaction.amount / 100).toFixed(2),
      },
    });

    session.onvalidatemerchant = async (event) => {
      await getApplePaySessionObject(event.validationURL)
        .then((merchantSession: any) => {
          session.completeMerchantValidation(merchantSession);
        })
        .catch(() => {
          session.abort();
          dispatch(setError('error.api-error'));
        });
    };

    session.onpaymentauthorized = async (event) => {
      const CreditCardType = matchCreditCardType(
        event.payment.token.paymentMethod.network
      );
      const Last4Digits = event.payment.token.paymentMethod.displayName.slice(
        -4
      );
      const token = JSON.stringify(event.payment.token.paymentData);

      dispatch(setDigitalWalletCreditCard(Last4Digits ?? '', CreditCardType));
      session.completePayment(ApplePaySession.STATUS_SUCCESS);
      dispatch(payForApplePayTransaction(token, CreditCardType, Last4Digits));
    };

    session.begin();
  }

  return (
    <>
      <button
        id="chooseCreditCardPaymentMethodBtn"
        className={`${paymentMethodclasses.PaymentMethodButton} ButtonOutlined`}
        onClick={onApplePayButtonClicked}
      >
        <div className="apple-pay-button-with-text apple-pay-button-white-with-text">
          <span className="text">Pay with</span>
          <ApplePayIcon className="logo" />
        </div>
      </button>
    </>
  );
};

export default HeartlandApplePayButton;
