import React, { useCallback, useEffect, useState } from 'react';
import paymentMethodclasses from '../PaymentMethods/PaymentMethods.module.css';
import { useTranslation } from 'react-i18next';
import {
  AppState,
  MerchantState,
  HeartlandMerchantConfiguration,
  TransactionState,
  ErrorState,
} from '../../../store/types/appState';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/ui/Loader/Loader';
import useScript from '../../../hooks/useScript';
import { setError } from '../../../store/actions';
import { ReactComponent as GooglePayIcon } from '../../../assets/google-pay.svg';
import { payForHeartlandGooglePayTransaction } from '../../../store/actions/heartland';
import { matchCreditCardType } from '../../../utils/utils';

const config = (window as any).config;

const HeartlandGooglePayButton = () => {
  const [isReadyToPay, setIsReadyToPay] = useState(false);
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [googlePayClient, setGooglePayClient] = useState<
    google.payments.api.PaymentsClient | undefined
  >();

  const merchant = useSelector<AppState, MerchantState>(
    (state) => state.merchant
  );
  const transaction = useSelector<AppState, TransactionState>(
    (state) => state.transaction
  );

  const error = useSelector<AppState, ErrorState>((state) => state.error);

  const heartlandErrorHandler = useCallback(() => {
    dispatch(setError('error.third-party-script', false));
  }, [dispatch]);

  const isScriptLoaded = useScript(
    config.googlePayApiJs,
    {},
    heartlandErrorHandler
  );

  const processPayment = (paymentData: google.payments.api.PaymentData) => {
    const CreditCardType = matchCreditCardType(
      paymentData.paymentMethodData.info?.cardNetwork
    );
    const Last4Digits = paymentData.paymentMethodData.info?.cardDetails;
    const token = paymentData.paymentMethodData.tokenizationData.token;

    dispatch(payForHeartlandGooglePayTransaction(token, CreditCardType, Last4Digits));
    setProcessing(true);
  };

  const [t] = useTranslation();
  const heartlandConfiguration = merchant.configuration as HeartlandMerchantConfiguration;

  const googlePayBaseConfiguration = {
    apiVersion: config.heartlandGooglePay.apiVersion.apiVersion,
    apiVersionMinor: config.heartlandGooglePay.apiVersion.apiVersionMinor,
    allowedPaymentMethods: [
      {
        type: config.heartlandGooglePay.cardPaymentMethod.type,
        parameters: {
          allowedAuthMethods:
            config.heartlandGooglePay.cardPaymentMethod.parameters
              .allowedAuthMethods,
          allowedCardNetworks:
            config.heartlandGooglePay.cardPaymentMethod.parameters
              .allowedCardNetworks,
        },
        tokenizationSpecification: {
          type: config.heartlandGooglePay.tokenizationSpecification.type,
          parameters: {
            gateway:
              config.heartlandGooglePay.tokenizationSpecification.gateway,
            gatewayMerchantId: heartlandConfiguration.gatewayMerchantId,
          },
        },
      },
    ],
  };

  function chooseEnvironment(
    environment: string
  ): google.payments.api.Environment {
    if (environment.toUpperCase() === 'PRODUCTION') return 'PRODUCTION';
    else return 'TEST';
  }

  useEffect(() => {
    if (
      isScriptLoaded &&
      googlePayClient === undefined &&
      heartlandConfiguration
    ) {
      const paymentClient = new google.payments.api.PaymentsClient({
        environment: chooseEnvironment(
          heartlandConfiguration.googleEnvironment
        ),
      });
      paymentClient
        .isReadyToPay({
          ...googlePayBaseConfiguration,
          existingPaymentMethodRequired: false,
        })
        .then((response) => {
          if (response.result) setIsReadyToPay(true);
        });
      setGooglePayClient(paymentClient);
    }
  }, [
    googlePayClient,
    heartlandConfiguration,
    isScriptLoaded,
    googlePayBaseConfiguration,
  ]);

  const loadPaymentData = () => {
    googlePayClient
      ?.loadPaymentData({
        ...googlePayBaseConfiguration,
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: t('payment.total'),
          totalPrice: transaction.amount.toFixed(2),
          currencyCode: heartlandConfiguration.currencyCode,
          countryCode: 'US',
          checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
        },
        merchantInfo: {
          merchantName: heartlandConfiguration.googleMerchantName,
          merchantId: heartlandConfiguration.googleMerchantId,
        },
      })
      .then(processPayment);
  };

  return (
    <>
      {!error.isError && processing ? <Loader /> : null}
      {isReadyToPay ? (
        <button
          id="chooseHeartlandGooglePayMethodBtn"
          className={`${paymentMethodclasses.PaymentMethodButton} ButtonOutlined`}
          onClick={loadPaymentData}
        >
          <GooglePayIcon />
          {t('payment.google-pay')}
        </button>
      ) : null}
    </>
  );
};

export default HeartlandGooglePayButton;
