export default class ApiMethods {
  static Portal = {
    Error: 'api/v1/portal/error',
    Merchant: 'api/v1/portal/merchant',
    RedirectOneTimePayment: 'api/v1/portal/redirect-one-time-payment',
    RedirectData: 'api/v1/portal/redirect-data',
    RedirectCustomSettings: 'api/v1/portal/redirect-settings',
    UserTimer: 'api/v1/portal/user-timer',
    Auth: 'api/v1/portal/auth',
    NMITransaction: {
      CreditCard: 'api/v1/portal/nmi-transaction/credit-card',
      BankAccount: 'api/v1/portal/nmi-transaction/bank-account',
      Base: 'api/v1/portal/nmi-transaction',
      PaymentMethod: 'api/v1/portal/nmi-transaction/payment-method',
      GooglePay: 'api/v1/portal/nmi-transaction/google-pay',
    },
    NMIPaymentMethod: {
      NMICreditCard: 'api/v1/portal/nmi-payment-methods/credit-card',
      NMIeCheck: 'api/v1/portal/nmi-payment-methods/echeck',
    },
    StripeMexicoTransaction: {
      CreditCard: 'api/v1/portal/stripe-mexico-transaction/credit-card',
      Base: 'api/v1/portal/stripe-mexico-transaction',
    },
    StripeMexicoPaymentMethod: {
      CreditCard: 'api/v1/portal/stripe-mexico-payment-methods/credit-card',
    },
    StripeChekoutSession: {
      Base: 'api/v1/portal/stripe-checkout-session'
    },
    HeartlandTransaction: {
      Base: 'api/v1/portal/heartland-transaction',
      CreditCard: 'api/v1/portal/heartland-transaction/credit-card',
      GooglePay: 'api/v1/portal/heartland-google-pay-transaction'
    },
    HeartlandPaymentMethod: {
      CreditCard: 'api/v1/portal/heartland-payment-method/credit-card',
    },
    RedirectManagePaymentMethods:
      'api/v1/portal/redirect-manage-payment-methods',
    PaymentMethods: 'api/v1/portal/payment-method',
    RedirectType: 'api/v1/portal/redirect-type',
    AutoPayment: {
      AutoPaymentDetails: 'api/v1/portal/redirect-autopayment',
      OneTimePayment: 'api/v1/portal/nmi-auto-payment/one-time-payment',
      CreateAutoPayment: 'api/v1/portal/nmi-auto-payment/auto-payment',
    },
    GooglePayTransaction: {
      GooglePay: 'api/v1/portal/heartland-google-pay-transaction',
    },
    ApplePayTransaction: {
      ApplePaySession: 'api/v1/portal/applepay-session',
      ApplePay: 'api/v1/portal/heartland-apple-pay-transaction',
    },
  };
}
