import * as actionTypes from '../actions/actionTypes';
import { Actions } from '../actions/heartland';
import { HeartlandState, PaymentMethodType } from '../types/appState';

const initialState: HeartlandState = {
  paymentMethod: null,
};

const setPaymentData = (
  state: HeartlandState,
  token: string,
  address: string,
  zipcode: string
): HeartlandState => {
  return {
    ...state,
    paymentMethod: {
      ...state.paymentMethod,
      type: PaymentMethodType.NewCC,
      token: token,
      address: address,
      zipcode: zipcode
    },
  };
};

const setTransactionCreditCard = (state: HeartlandState, paymentMethodId: string): HeartlandState => {
  return {
    ...state,
    paymentMethod: {
      ...state.paymentMethod,
      type: PaymentMethodType.ExistingCC,
      paymentMethodId: paymentMethodId
    }
  }
}

const reducer = (
  state: HeartlandState = initialState,
  action: Actions
): HeartlandState => {
  switch (action.type) {
    case actionTypes.SET_HEARTLAND_PAYMENT_DATA:
      return setPaymentData(state, action.token, action.address, action.zipcode);
    case actionTypes.SET_HEARTLAND_TRANSACTION_CREDIT_CARD:
      return setTransactionCreditCard(state, action.creditCardId)
    default:
      return state;
  }
};

export default reducer;
