export const inputStyle = {
  'border-width': '0',
  'font-size': '16px',
  'padding-bottom': '1px',
  'padding-top': '1px',
  'height': '22px',
}

export const inputFocusStyles = {

}

export const submitButton = {
  'color': '#ffffff',
  'background': '#5c81ea',
  'border-radius': '8px',
  'border': '0',
  'height': '42px',
  'width': '100%',
  'font-size': '16px',
  'font-weight': 'bold',
  'cursor': 'pointer'
}

export const submitButtonHover = {
  "background": '#7d9aee'
}

export const submitButtonActove = {
  "background": '#486aca'
}