import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  transactionCompleted,
  setError,
  transactionFail,
} from '.';
import ApiMethods from '../../constants/urls';
import {
  ApiTransactionStatus,
  CreditCardType,
  TenderType,
} from '../../types/api/apiEnums';
import axios from '../../utils/axios-tezpay';
import { transactionStatus } from '../../utils/utils';
import {
  AppState,
  PayForStripeMexicoTransactionData,
  PaymentMethodType,
  TransactionStatus,
} from '../types/appState';
import * as actionTypes from './actionTypes';
import {
  addPaymentMethodDecline,
  addPaymentMethodFail,
  addPaymentMethodSuccess,
  deletePaymentMethodDecline,
  deletePaymentMethodFail,
  deletePaymentMethodSuccess,
} from './paymentMethods';

export const setStripeMexicoTransactionCreditCard = (paymentMethodId: string) => {
  return {
    type: actionTypes.SET_STRIPE_MEXICO_TRANSACTION_CREDIT_CARD as typeof actionTypes.SET_STRIPE_MEXICO_TRANSACTION_CREDIT_CARD,
    paymentMethodId,
  };
};

export const payForStripeMexicoTransaction = () => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(payForStripeMexicoTransactionStart());

    const state = getState();
    const stripe = state.stripeMexico;

    let url = '';
    let data = null;

    switch (stripe.paymentMethod?.type) {
      case PaymentMethodType.NewCC:
        if (stripe.paymentMethod.paymentMethodId !== '') {
          url = ApiMethods.Portal.StripeMexicoTransaction.Base;
          const temp: PayForStripeMexicoTransactionData = {
            paymentMethodId: stripe.paymentMethod.paymentMethodId,
            saveCreditCard: state.transaction.savePaymentMethod,
          };
          data = temp;
        } else {
          console.error('paymentToken is null');
          dispatch(setError('error.unknown-error'));
        }
        break;
      case PaymentMethodType.ExistingCC:
        url = ApiMethods.Portal.StripeMexicoTransaction.CreditCard;
        url += `/${stripe.paymentMethod.paymentMethodId}`;
        break;
      default:
        console.error('unknown payment method');
        dispatch(setError('error.unknown-error'));
        break;
    }

    axios
      .post(url, data)
      .then((response) => {
        const status = transactionStatus(response.data.data.transactionStatus as ApiTransactionStatus);
        if (status !== TransactionStatus.Unknown)
          dispatch(transactionCompleted(status))
        else
          dispatch(setError('error.unknown-error'));
      })
      .catch((error) => {
        dispatch(transactionFail());
        if (error.response && error.response.status === 401)
          dispatch(setError('error.unauthorized', false));
        else dispatch(setError('error.api-error'));
      })
      .finally(() => {
        dispatch(payForStripeMexicoTransactionEnd())
      });
  };
};

export const payForStripeMexicoTransactionStart = () => {
  return {
    type: actionTypes.PAY_FOR_STRIPE_MEXICO_TRANSACTION_START as typeof actionTypes.PAY_FOR_STRIPE_MEXICO_TRANSACTION_START,
  };
};

export const payForStripeMexicoTransactionEnd = () => {
  return {
    type: actionTypes.PAY_FOR_STRIPE_MEXICO_TRANSACTION_END as typeof actionTypes.PAY_FOR_STRIPE_MEXICO_TRANSACTION_END,
  };
};

export const addStripeMexicoPaymentMethod = () => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(addStripeMexicoPaymentMethodStart());

    const state = getState();
    const stripe = state.stripeMexico;

    let url;
    let data;
    if (stripe.paymentMethod?.type === PaymentMethodType.NewCC) {
      data = {
        paymentMethodId: stripe.paymentMethod.paymentMethodId,
      };
      url = ApiMethods.Portal.StripeMexicoPaymentMethod.CreditCard;
    } else
      throw new Error('Cannot add StripeMexico payment methods with type ExistingCC');

    if (url !== '') {
      axios
        .post(url, data)
        .then((response) => {
          const status = response.data.data
            .transactionStatus as ApiTransactionStatus;

          switch (status) {
            case ApiTransactionStatus.Approved:
              dispatch(addPaymentMethodSuccess());
              dispatch(addStripeMexicoPaymentMethodSuccess());
              break;
            case ApiTransactionStatus.Declined:
              dispatch(setError('error.adding-payment-method-failed'));
              dispatch(addPaymentMethodDecline());
              dispatch(addStripeMexicoPaymentMethodDecline());
              break;
            default:
              console.error('status is not known');
              dispatch(setError('error.unknown-error'));
              break;
          }
        })
        .catch((error) => {
          dispatch(addPaymentMethodFail());
          dispatch(addStripeMexicoPaymentMethodFail());
          if (error.response && error.response.status === 401)
            dispatch(setError('error.unauthorized', false));
          else dispatch(setError('error.api-error'));
        });
    } else {
      console.error('url not set');
      dispatch(setError('error.unknown-error'));
    }
  };
};

export const addStripeMexicoPaymentMethodStart = () => {
  return {
    type: actionTypes.ADD_STRIPE_MEXICO_PAYMENT_METHOD_START as typeof actionTypes.ADD_STRIPE_MEXICO_PAYMENT_METHOD_START,
  };
};

export const addStripeMexicoPaymentMethodSuccess = () => {
  return {
    type: actionTypes.ADD_STRIPE_MEXICO_PAYMENT_METHOD_SUCCESS as typeof actionTypes.ADD_STRIPE_MEXICO_PAYMENT_METHOD_SUCCESS,
  };
};

export const addStripeMexicoPaymentMethodDecline = () => {
  return {
    type: actionTypes.ADD_STRIPE_MEXICO_PAYMENT_METHOD_DECLINE as typeof actionTypes.ADD_STRIPE_MEXICO_PAYMENT_METHOD_DECLINE,
  };
};

export const addStripeMexicoPaymentMethodFail = () => {
  return {
    type: actionTypes.ADD_STRIPE_MEXICO_PAYMENT_METHOD_FAIL as typeof actionTypes.ADD_STRIPE_MEXICO_PAYMENT_METHOD_FAIL,
  };
};

export const setStripeMexicoPaymentToken = (
  paymentToken: string,
  ccType: CreditCardType
) => ({
  type: actionTypes.SET_STRIPE_MEXICO_PAYMENT_TOKEN as typeof actionTypes.SET_STRIPE_MEXICO_PAYMENT_TOKEN,
  paymentToken: paymentToken,
  ccType: ccType,
});

export const setStripeMexicoPaymentData = (zipCode: string) => {
  return {
    type: actionTypes.SET_STRIPE_MEXICO_PAYMENT_DATA as typeof actionTypes.SET_STRIPE_MEXICO_PAYMENT_DATA,
    zipCode: zipCode,
  };
};

export const deleteStripeMexicoPaymentMethod = (
  paymentMethodId: string,
  tenderType: TenderType
) => {
  return (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>,
    getState: () => AppState
  ) => {
    dispatch(deleteStripeMexicoPaymentMethodStart());

    let url;

    switch (tenderType) {
      case TenderType.CreditCard:
        url = ApiMethods.Portal.StripeMexicoPaymentMethod.CreditCard;
        break;
      default:
        console.error('tenderType is not known');
        dispatch(setError('error.unknown-error'));
        break;
    }

    if (url && url !== '') {
      url += `/${paymentMethodId}`;

      axios
        .delete(url)
        .then((response) => {
          const status = response.data.data
            .transactionStatus as ApiTransactionStatus;
          switch (status) {
            case ApiTransactionStatus.Approved:
              dispatch(deletePaymentMethodSuccess());
              dispatch(deleteStripeMexicoPaymentMethodSuccess());
              break;
            case ApiTransactionStatus.Declined:
              dispatch(setError('error.deleteing-payment-method-failed'));
              dispatch(deletePaymentMethodDecline());
              dispatch(deleteStripeMexicoPaymentMethodDecline());
              break;
            default:
              console.error('status is not known');
              dispatch(setError('error.unknown-error'));
              break;
          }
        })
        .catch((error) => {
          dispatch(deletePaymentMethodFail());
          dispatch(deleteStripeMexicoPaymentMethodFail());
          if (error.response && error.response.status === 401)
            dispatch(setError('error.unauthorized', false));
          if (error.response && error.response.data.error.errorCode === 2010)
            dispatch(
              setError('error.pending-pre-auth-credit-card-transaction')
            );
          else dispatch(setError('error.api-error'));
        });
    } else {
      console.error('url not set');
      dispatch(setError('error.unknown-error'));
    }
  };
};

export const deleteStripeMexicoPaymentMethodStart = () => {
  return {
    type: actionTypes.DELETE_STRIPE_MEXICO_PAYMENT_METHOD_START as typeof actionTypes.DELETE_STRIPE_MEXICO_PAYMENT_METHOD_START,
  };
};

export const deleteStripeMexicoPaymentMethodSuccess = () => {
  return {
    type: actionTypes.DELETE_STRIPE_MEXICO_PAYMENT_METHOD_SUCCESS as typeof actionTypes.DELETE_STRIPE_MEXICO_PAYMENT_METHOD_SUCCESS,
  };
};

export const deleteStripeMexicoPaymentMethodDecline = () => {
  return {
    type: actionTypes.DELETE_STRIPE_MEXICO_PAYMENT_METHOD_DECLINE as typeof actionTypes.DELETE_STRIPE_MEXICO_PAYMENT_METHOD_DECLINE,
  };
};

export const deleteStripeMexicoPaymentMethodFail = () => {
  return {
    type: actionTypes.DELETE_STRIPE_MEXICO_PAYMENT_METHOD_FAIL as typeof actionTypes.DELETE_STRIPE_MEXICO_PAYMENT_METHOD_FAIL,
  };
};

export type Actions =
  | ReturnType<typeof setStripeMexicoPaymentToken>
  | ReturnType<typeof setStripeMexicoPaymentData>
  | ReturnType<typeof payForStripeMexicoTransactionStart>
  | ReturnType<typeof setStripeMexicoTransactionCreditCard>
  | ReturnType<typeof addStripeMexicoPaymentMethodStart>
  | ReturnType<typeof deleteStripeMexicoPaymentMethodStart>
  | ReturnType<typeof deleteStripeMexicoPaymentMethodSuccess>
  | ReturnType<typeof deleteStripeMexicoPaymentMethodDecline>
  | ReturnType<typeof deleteStripeMexicoPaymentMethodFail>;
