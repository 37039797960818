import React from 'react';
import {
  AppState,
  ProviderType,
} from '../../../store/types/appState';
import NMIGooglePayButton from './NMIGooglePayButton';
import HeartlandGooglePayButton from './HeartlandGooglePayButton';
import { useSelector } from 'react-redux';


const GooglePayButton = () => {

  const providerType = useSelector<AppState>(x => x.merchant.providerType)

  let googlePayButtonComponent;
  switch (providerType) {
    case ProviderType.NMI:
    case ProviderType.ProPayCNP_NMI:
      googlePayButtonComponent = (
        <NMIGooglePayButton />
      );
      break;
    case ProviderType.Heartland:
    case ProviderType.ProPayCNP_Heartland:
      googlePayButtonComponent = (
        <HeartlandGooglePayButton />
      );
      break;
    default:
      googlePayButtonComponent = null;
      break;
  }

  return (
    <>
      {googlePayButtonComponent}
    </>
  );
};

export default GooglePayButton;
