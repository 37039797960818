import React from 'react';
import { ProviderType } from '../../../store/types/appState';
import Header from '../../ui/layout/Header/Header';
import StripeExternalForm from './StripeExternalForm';
import { useTranslation } from 'react-i18next';

export type Props = {
  providerType: ProviderType;
  back: () => void;
};

const ExternalForm = (props: Props) => {
  const [t] = useTranslation();

  let externalFormComponent;
  switch (props.providerType) {
    case ProviderType.Stripe:
        externalFormComponent = (<StripeExternalForm />)
      break;
    default:
        externalFormComponent = null;
      break;
  }

  return (
    <>
      <Header
        title={t('payment.credit-card-details')}
        subTitle={t('payment.enter-credit-card-details')}
        showBackButton={true}
        back={props.back} />
      {externalFormComponent}
    </>
  );
};

export default ExternalForm;