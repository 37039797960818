import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppState,
  HeartlandMerchantConfiguration,
  MerchantState,
  PaymentMethodHeartlandProviderType,
} from '../../../../../store/types/appState';
import Loader from '../../../../ui/Loader/Loader';
import classes from './HeartlandAddCreditCard.module.scss';
import useScript from '../../../../../hooks/useScript';
import { heartlandConfig } from '../../../../../utils/configs';
import { setError, setPaymentData } from '../../../../../store/actions';
import { CreditCardType, PortalHideControl } from '../../../../../types/api/apiEnums';
import { setHeartlandPaymentData } from '../../../../../store/actions/heartland';
import GlobalPayments from '@globalpayments/js' // eslint-disable-line
import Input from '../../../../ui/Inputs/Input/Input';
import BottomButtons from '../../../../ui/layout/BottomButtons/BottomButtons';
import { inputStyle, inputFocusStyles, submitButton, submitButtonHover, submitButtonActove } from './HeartlandStyles';
import Checkbox from '../../../../ui/Inputs/Checkbox/Checkbox';
import UIForm from '@globalpayments/js/types/ui/form';

export type Props = {
  next: (params: PaymentMethodHeartlandProviderType) => void;
};

const HeartlandAddCreditCard = ({ next }: Props) => {
  const dispatch = useDispatch();

  const merchant = useSelector<AppState, MerchantState>(
    (state) => state.merchant
  );
  const merchantConfiguration = merchant.configuration as HeartlandMerchantConfiguration;

  const hideControls = useSelector<AppState, PortalHideControl>(
    (state) => state.redirect.hideControls != null ? state.redirect.hideControls : 0
  );

  const isHiddenField = useCallback((field: PortalHideControl): boolean => {
    return (hideControls & field) > 0;
  }, [hideControls])

  const [t] = useTranslation();

  const [isConfigured, setIsConfigured] = useState(false);

  const isSavingPaymentMethodAllowed = useSelector<AppState, boolean>(
    (state) => state.transaction.isSavingPaymentMethodAllowed && !isHiddenField(PortalHideControl.SaveCCBtn)
  );

  const [validationErrors, setValidationErrors] = useState<String[]>([]);

  const heartlandErrorHandler = useCallback(() => {
    dispatch(setError('error.third-party-script', false))
  }, [dispatch]);

  const isScriptLoaded = useScript(heartlandConfig.globalPaymentsJs, {}, heartlandErrorHandler);

  const [saveCard, setSaveCard] = useState(false);

  const matchCreditCardType = (type: string): CreditCardType => {
    switch (type) {
      case 'visa':
        return CreditCardType.Visa;
      case 'amex':
        return CreditCardType.AmericanExpress;
      case 'diners':
        return CreditCardType.DinersClub;
      case 'discover':
        return CreditCardType.Discover;
      case 'jcb':
        return CreditCardType.JCB;
      case 'mastercard':
        return CreditCardType.MasterCard;
      default:
        return CreditCardType.Invalid;
    }
  };

  const [heartlandForm, setHeartlandForm] = useState<UIForm | null>(null)

  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');

  const [heartlandResponse, setHeartlandResponse] = useState<any>(null);

  const [runPostTokenSuccessValidation, setRunPostTokenSuccessValidation] = useState<boolean>(false);

  useEffect(() => {
    if (heartlandResponse !== null && runPostTokenSuccessValidation) {
      setRunPostTokenSuccessValidation(false)

      let internalValidationErrors: string[] = []

      let areAdditionalFieldsValid = true;
      if (!isHiddenField(PortalHideControl.Address) && address.length <= 0) {
        areAdditionalFieldsValid = false;
        internalValidationErrors = [...internalValidationErrors, t('payment.address-required')]
      }

      if (!isHiddenField(PortalHideControl.ZipCode)) {
        if (zipCode.length <= 0) {
          areAdditionalFieldsValid = false;
          internalValidationErrors = [...internalValidationErrors, t('payment.zipcode-required')]
        }

        if (!/^[0-9A-Za-z]*$/.test(zipCode)) {
          areAdditionalFieldsValid = false;
          internalValidationErrors = [...internalValidationErrors, t('payment.zipcode-invalid')]
        }
      }



      if ((!isHiddenField(PortalHideControl.FirstName) || !isHiddenField(PortalHideControl.LastName)) && heartlandResponse.details.cardholderName === undefined) {
        areAdditionalFieldsValid = false;
        internalValidationErrors = [...internalValidationErrors, t('payment.name-required')]
      }

      if (heartlandResponse.details.cardSecurityCode === false) {
        areAdditionalFieldsValid = false;
        internalValidationErrors = [...internalValidationErrors, t('payment.cvv-required')]
      }

      if (heartlandResponse.details.expiryMonth === undefined || heartlandResponse.details.expiryYear === undefined) {
        areAdditionalFieldsValid = false;
        internalValidationErrors = [...internalValidationErrors, t('payment.expiration-date-required')]
      }

      if (internalValidationErrors.length > 0)
        setValidationErrors(internalValidationErrors)

      if (areAdditionalFieldsValid) {
        const ccType = matchCreditCardType(heartlandResponse.details.cardType);
        dispatch(setPaymentData(saveCard));
        dispatch(setHeartlandPaymentData(heartlandResponse.paymentReference, address, zipCode));
        next({ last4Digits: heartlandResponse.details.cardLast4, ccType });
      }
    }
  }, [heartlandResponse, address, dispatch, isHiddenField, next, saveCard, t, validationErrors, zipCode, runPostTokenSuccessValidation])

  useEffect(() => {
    if (isScriptLoaded) {
      window.GlobalPayments.configure({
        publicApiKey: merchantConfiguration.publicKey
      })

      let fields: any = {
        "card-number": {
          target: "#credit-card-card-number"
        },
        "card-expiration": {
          target: "#credit-card-card-expiration"
        },
        "card-cvv": {
          target: "#credit-card-card-cvv",
        },
        "submit": {
          target: "#credit-card-submit"
        }
      }

      if (!isHiddenField(PortalHideControl.FirstName) && !isHiddenField(PortalHideControl.LastName)) {
        fields = {
          ...fields,
          "card-holder-name": {
            target: "#credit-card-card-holder",
          },
        }
      }

      const form = window.GlobalPayments.ui.form({
        fields: fields,
        styles: {
          ".card-holder-name": inputStyle,
          ".card-holder-name:focus": inputFocusStyles,
          ".card-number": inputStyle,
          ".card-number:focus": inputFocusStyles,
          ".card-expiration": inputStyle,
          ".card-expiration:focus": inputFocusStyles,
          ".card-cvv": inputStyle,
          ".card-cvv:focus": inputFocusStyles,
          ".submit": submitButton,
          ".submit:hover": submitButtonHover,
          ".submit:active": submitButtonActove
        }
      })

      setHeartlandForm(form);
    }
  }, [isScriptLoaded, merchantConfiguration.publicKey, isHiddenField])

  useEffect(() => {
    if (heartlandForm !== null) {
      // @ts-ignore
      heartlandForm.on("token-success", (resp: any) => {
        setHeartlandResponse(resp)
        setRunPostTokenSuccessValidation(true)
      });
      // @ts-ignore
      heartlandForm.on("token-error", (resp: any) => {
        setValidationErrors(resp.reasons.map((reason: any) => reason.message))
      });
      heartlandForm.on("error", () => {
        dispatch(setError('error.third-party-script', false))
      });
      heartlandForm.ready(() => {
        setIsConfigured(true);
      });
    }
  }, [dispatch, heartlandForm])

  return (
    <>
      {(!isConfigured) ? <Loader /> : null}
      <div>
        <div className={classes.CreditCardInputs}>
          <div className={classes.InputsWrapper}>
            <Input
              title={t('name')}
              className={`${classes.NameInput} ${isHiddenField(PortalHideControl.FirstName) || isHiddenField(PortalHideControl.LastName) ? classes.HiddenField : ''}`} >
              <div id="credit-card-card-holder" className={classes.Input} />
            </Input>
          </div>
          <Input
            title={t('payment.credit-card-number')}
          >
            <div id="credit-card-card-number" className={classes.Input} />
          </Input>
          <div className={classes.InputsWrapper}>
            <div className={classes.CcexpInput}>
              <Input title={t('payment.expiration-date')}>
                <div id="credit-card-card-expiration" className={classes.Input} />
              </Input>
            </div>
            <div className={classes.CcvInput}>
              <Input title={t('payment.cvv')}>
                <div id="credit-card-card-cvv" className={classes.Input} />
              </Input>
            </div>

            <div className={`${classes.ZipCodeInput} ${isHiddenField(PortalHideControl.ZipCode) ? classes.HiddenField : ''}`}>
              <Input title={t('payment.zip')}>
                <div style={{ height: '37px' }} >
                  <input
                    id="heartlandZipInput"
                    type="text"
                    value={zipCode}
                    maxLength={9}
                    onChange={(event) => { setZipCode(event.target.value) }}
                  />
                </div>
              </Input>
            </div>
          </div>

          <div className={`${isHiddenField(PortalHideControl.Address) ? classes.HiddenField : ''}`}>
            <Input title={t('address')}>
              < input
                id="heartlandAddressInput"
                type="text"
                value={address}
                maxLength={150}
                onChange={(event) => { setAddress(event.target.value) }}
              />
            </Input>
          </div>

          {isSavingPaymentMethodAllowed && (
            <Checkbox
              id="setHearltandSaveCardCheckbox"
              label={t('payment.save-card-future')}
              isChecked={saveCard}
              onChange={(isChecked) => setSaveCard(isChecked)}
            />
          )}
        </div >
      </div>

      {validationErrors.length > 0 && (
        <div className={classes.Validation}>
          {validationErrors.map((validationError, index) =>
            <label key={index}>{validationError}</label>
          )}
        </div>

      )}

      <BottomButtons>
        <div className={classes.SubmitWrapper} id="credit-card-submit"></div>
      </BottomButtons>
    </>
  );
};

export default HeartlandAddCreditCard