import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useLoadStripeMexico from '../../../../../hooks/useLoadStripeMexico';
import { setPaymentData } from '../../../../../store/actions';
import {
  setStripeMexicoPaymentData,
  setStripeMexicoPaymentToken,
} from '../../../../../store/actions/stripeMexico';
import {
  AppState,
  PaymentMethodStripeMexicoProviderType,
} from '../../../../../store/types/appState';
import { CreditCardType } from '../../../../../types/api/apiEnums';
import Loader from '../../../../ui/Loader/Loader';
import classes from './StripeMexicoAddCreditCard.module.scss';
import BottomButton from '../../../../ui/layout/BottomButtons/BottomButton';
import BottomButtons from '../../../../ui/layout/BottomButtons/BottomButtons';
import Checkbox from '../../../../ui/Inputs/Checkbox/Checkbox';

const CARD_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Lato',
      fontSize: '16px',

      '::placeholder': {
        color: '#a5a5a5',
        fontSize: '10px',
        fontFamily: 'Arial',
      },
    },
  },
};

export type Props = {
  next: (params: PaymentMethodStripeMexicoProviderType) => void;
};

const StripeMexicoAddCreditCard = ({ next }: Props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const stripe = useStripe();

  const elements = useElements();
  const [error, setError] = useState<any>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const isSavingPaymentMethodAllowed = useSelector<AppState, boolean>(
    (state) => state.transaction.isSavingPaymentMethodAllowed
  );

  const [saveCard, setSaveCard] = useState(false);

  const matchCreditCardType = (type: string): CreditCardType => {
    switch (type) {
      case 'visa':
        return CreditCardType.Visa;
      case 'amex':
        return CreditCardType.AmericanExpress;
      case 'diners':
        return CreditCardType.DinersClub;
      case 'discover':
        return CreditCardType.Discover;
      case 'jcb':
        return CreditCardType.JCB;
      case 'mastercard':
        return CreditCardType.MasterCard;
      default:
        return CreditCardType.Invalid;
    }
  };

  const nextHandler = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card')?.focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      setProcessing(false);

      if (payload.error) {
        setError(payload.error);
      } else if (!!payload.paymentMethod) {
        const payment = payload.paymentMethod;
        const ccType = matchCreditCardType(payment.card?.brand || '');
        dispatch(setPaymentData(saveCard));
        dispatch(
          setStripeMexicoPaymentData(
            payment.billing_details.address?.postal_code || ''
          )
        );
        dispatch(setStripeMexicoPaymentToken(payment.id, ccType));

        next({
          paymentMethodId: payment.id,
          last4Digits: payment.card?.last4 || '',
          ccType,
        });
      }
    }
  };

  return (
    <>
      {!stripe || processing ? <Loader /> : null}
      <div>
        <div className={classes.CreditCardInputs}>
          <div
            id="stripeWrapExternalControlDiv"
            className={cx(classes.FormRow, {
              [classes.Invalid]: !!error,
            })}
          >
            <CardElement
              id="stripeCardElement"
              options={CARD_OPTIONS}
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </div>
          {error && <span className={classes.Error}>{error.message}</span>}

          {isSavingPaymentMethodAllowed && (
            <Checkbox
              id="setStripeSaveCardCheckbox"
              label={t('payment.save-card-future')}
              isChecked={saveCard}
              onChange={(isChecked) => setSaveCard(isChecked)}
            />
          )}
        </div>
      </div>

      <BottomButtons>
        <BottomButton
          id="addStripeCCConfirm"
          className={`ButtonPrimary`}
          onClick={nextHandler}
          disabled={processing}
          title={t('payment.confirm')}
          grow={undefined}
        />
      </BottomButtons>
    </>
  );
};

const StripeAddCreditCardWrapper = (props: Props) => {
  const { stripePromise } = useLoadStripeMexico();

  return (
    <Elements stripe={stripePromise}>
      <StripeMexicoAddCreditCard {...props} />
    </Elements>
  );
};

export default StripeAddCreditCardWrapper;
